import React from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";

export function VerticalTilesPanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  const bgColor = cmsUtils.payload(item, "BgColor");
  const imageUrl = cmsUtils.payload(item, "ImageUrl");
  const backgroundTile = cmsUtils.payload(item, "BackgroundTile");
  const bgTheme = cmsUtils.payload(item, "TextColorTheme") || "white";

  let bgUrl = null;
  if (imageUrl) bgUrl = utils.site.resourcePath(imageUrl);
  else if (backgroundTile)
    bgUrl = utils.site.resourcePath(
      `/dist/transparent-textures/textures/${backgroundTile}`
    );
  const cssClass = utils.classNames(
    "cms_item",
    "Vertical-Tiles-Panel",
    "Vertical-Tiles-Panel--" + bgTheme,
    item.cssClass || item.anchorName || ""
  );

  const subItemsElts = subItems.map((subItem, index) => (
    <div className='wrapper'>
      <div className='vt_overlay'></div>
      <div className='img_bg'>
        <img src={subItem.payloads[0].value} alt='vertical-tile' />
      </div>
      <div className='des'>
        <div className='des_image'>
          <img src={subItem.payloads[0].value} alt='vertical-tile' />
        </div>
        <div className='des_content'>
          <div className='vt_title'>{subItem.payloads[1].value}</div>
          <div className='content'>{subItem.payloads[2].value}</div>
        </div>
      </div>
    </div>
  ));

  return (
    <section
      className={cssClass}
      style={{
        backgroundColor: `${bgColor}`,
        backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl),
      }}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      <div className='container'>
        <div className='verticalTilesPanel_container'>{subItemsElts}</div>
      </div>
    </section>
  );
}
