/** @jsx jsx */
import { jsx } from '@emotion/core'
// import cmsUtils from '../../cmsUtils';
import utils from 'utils'
import { ShareButtons } from 'site/shareButtons/shareButtons'

export function SharingIcons(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  //console.log(cmsOption)
  //console.log('item', item);

  const cssClass = utils.classNames('cms_item', item.cssClass || item.anchorName || '');
  const shareUrl = utils.site.fullUrl(window.location.pathname + window.location.search)

  return <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
    <ShareButtons shareUrl={shareUrl} />
  </div>
}
