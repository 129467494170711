import { css } from '@emotion/core'
import { variables, mq } from '../../cssInJs';
import { darken } from 'polished';

const buttonColor = variables.primaryColor;

const baseStyle = {
  button: mq({
    color: 'white',
    padding: '0.7rem 1.5rem 1rem 1.5rem',
    position: 'relative',
    overflow: 'hidden',
    display: 'inline-block',
    appearance: 'none',
    '-webkit-appearance': 'none',
    //margin: '0 0.5rem',
    '&:hover .buttonBg': {
      background: darken(0.1, buttonColor),
    },
    '&:hover': {
      color: 'white',
      textDecoration: 'none',
    }
  }),
  buttonBg: {
    transition: 'all 0.3s',
    borderRadius: '100%',
    position: 'absolute',
    background: buttonColor,
    right: '-200px',
    left: '-200px',
    top: '-200px',
    content: '""',
    bottom: '0',
    zIndex: '0',
  },
  buttonTxt: {
    zIndex: '1',
    position: 'relative',
  }
}

const smallStyle = {
  button: css(baseStyle.button, {
    fontSize: '0.85rem',
    fontWeight: 600,
    letterSpacing: 1,
    padding: '0.3rem 1.5rem 0.3rem 1.5rem',
    '&:hover .buttonBg': {
      background: darken(0.1, buttonColor),
    },
  }),
  buttonBg: css(baseStyle.buttonBg, {
    background: buttonColor,
  }),
  buttonTxt: baseStyle.buttonTxt
}

export function getButtonStyle(type) {
  if (type === 'small') {
    return smallStyle
  }
  return baseStyle
} 
