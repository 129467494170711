import { mq } from "../../cssInJs";

export default {
  container: mq({
    cursor: "pointer",
    zIndex: "1000",
    position: "fixed",
    padding: "0",
    //top: 4,
    right: ["50px", null, "5.5rem"],
    //border: '1px solid red'
  }),
  countContainer: mq({
    width: ["40px", null, "50px"],
    height: ["40px", null, "50px"],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "svg": mq({
      width: ["60%"],
      height: ["90%"],
      color: "#af5f27",
    }),
  }),
  count: mq({
    position: "absolute",
    backgroundColor: "#af5f27",
    color: "#fff",
    borderRadius: "20px",
    padding: "0rem 0.2rem",
    lineHeight: "1",
    top: ["3px", null, "0px"],
    right: "5px",
    //left : ['1.5rem', null, '2rem']
  }),
};
