// export { getOfflinePage, getOperators, getOperator, getOperatorById, getFeaturedOperators, getDestination, getExpTypes, getExpType } from './dbSelect'
// export { indexOfflineData } from './dbInsert'
// export { addCache, checkCache, existInCache, getAllImagesInCache } from './cache'
// export { getOfflineMedia } from './media'
// export { initInstallEventHandler, isInPWA } from './pwa'
// export { isIOS } from './device'

export function isOffline() {
  // return true;
  return !navigator.onLine;
}


export async function getStorageInfo() {
  const messages = []
  if (!('indexedDB' in window)) {
    console.log('not supported')
  }
  if ('caches' in window) {
    messages.push('Cache available')
  }
  if (navigator.storage && navigator.storage.estimate) {
    const quota = await navigator.storage.estimate();
    // quota.usage -> Number of bytes used.
    // quota.quota -> Maximum number of bytes available.
    const percentageUsed = ((quota.usage / quota.quota) * 100).toFixed(2);
    messages.push(`You've used ${percentageUsed}% of the available storage.`);
    const remaining = ((quota.quota - quota.usage) / 1024 / 1024).toFixed(2);
    messages.push(`You can write up to ${remaining} more MBs.`);
  }

  if (navigator.storage && navigator.storage.persist) {
    const persistent = await navigator.storage.persist();
    if (persistent)
      messages.push("Storage will not be cleared except by explicit user action");
    else
      messages.push("Storage may be cleared by the UA under storage pressure.");
  }

  return messages;
}
