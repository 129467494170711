//import React from 'react';
import cmsUtils from "../../../cms/utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../..";

/** @jsx jsx */
import { jsx } from "@emotion/core";
// import style from './style2';

export function PageTilesBsyc3(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, "PageObjects"));
  const pageTileElts = pages.map((page, index) => (
    <Tile
      page={page}
      key={index}
      disableLink={cmsOption.isCmsEdit}
      idx={index}
    ></Tile>
  ));

  const cssClass = utils.classNames(
    "cms_item",
    "pageTiles3",
    item.cssClass || item.anchorName || ""
  );

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  return (
    <div className='pageTile3__tile'>
      <div
        className='pageTile3__bg'
        style={{
          backgroundImage: utils.css.bgUrlStyle(imageUrl),
        }}
      >
        <div className='tile3__overlay'>
          <div className='tile3__details'>
            {page.pageTitle && page.pageTitle !== "" && (
              <div className='tile3__title'>{page.pageTitle}</div>
            )}

            <div className='tile3__body'>
              {page.tileDesc || page.description}
            </div>

            <SiteLink
              className='tile3__btn'
              to={disableLink ? "" : page.pageUrl}
            >
              READ MORE
            </SiteLink>
          </div>
        </div>
      </div>
    </div>
  );
}
