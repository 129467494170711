import { css } from '@emotion/core'
//import { mq } from 'cssInJs'

export default{
    testimonialPanel : css({
        marginTop: '20px',
        marginBottom: '20px',
        backgroundColor: 'transparent',
        '& .slick-prev:before, .slick-next:before' : {
            color : 'black'
        },
        '& .slick-next' : {
            right : '-2%'
        },
        '& .slick-prev' : {
            left : '-2%'
        }
    })
}