import React from 'react';

export function useStep(initialSteps){
  const [steps, setSteps] = React.useState(initialSteps);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [lastStep, setLastStep] = React.useState(0);

  const moveNext = () => {
    moveTo(currentStep < steps.length ? currentStep + 1 : currentStep)
  }

  const movePrev = () => {
    moveTo(currentStep > 0 ? currentStep - 1 : 0)
  }

  const moveTo = (index) => {
    if (!canGo(index)) return
    setLastStep(currentStep)
    setCurrentStep(index)
    // if (!utils.ui.isElementInViewport(navEl.current)) {
    //   utils.ui.scrollTo(navEl.current)
    // }
  }

  const markAsFilled = (index) => {
    if (!steps[index]) return;
    steps[index].filled = true;
    setSteps(steps);
  }
  
  function canGo(index) {
    if (steps[index] && steps[index].filled) return true;
    const maxIndexFilled = Math.max(...steps.map((x, i) => x.filled ? i : -1));
    return index <= maxIndexFilled + 1
  }

  function canGoNext() {
    return currentStep < steps.length && canGo(currentStep + 1)
  }

  function canGoPrev() {
    return currentStep > 0 && canGo(currentStep - 1)
  }

  return { steps, setSteps, currentStep, lastStep, moveTo, moveNext, movePrev, markAsFilled, canGo, canGoNext, canGoPrev}
}