import { css } from "@emotion/core";
import { variables, mq } from "../../../cssInJs";

const numberOfTiles = [1, 2, 3, 4];
//const marginLR = [14, 14, 14, 14]
const marginTB = [14, 14, 14, 14];

const widthPercent = (index) => 100 / numberOfTiles[index] + "%";

const linkText = {
  color: variables.fontColor,
  fontSize: "0.8rem",
  padding: "0 20px",
  "&:hover": {
    color: "#af5f27",
  },
};

export const tileListStyle = {
  tiles: mq({
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    //marginLeft: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    //marginRight: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    marginTop: [
      marginTB[0] / 2,
      marginTB[1] / 2,
      marginTB[2] / 2,
      marginTB[3] / 2,
    ],
    marginBottom: [
      marginTB[0] / 2,
      marginTB[1] / 2,
      marginTB[2] / 2,
      marginTB[3] / 2,
    ],
    maxWidth: ["800px"],
    margin: "auto",
  }),
  categoryList: mq({
    marginTop: [
      marginTB[0] / 2,
      marginTB[1] / 2,
      marginTB[2] / 2,
      marginTB[3] / 2,
    ],
    marginBottom: [
      marginTB[0] / 2,
      marginTB[1] / 2,
      marginTB[2] / 2,
      marginTB[3] / 2,
    ],
    maxWidth: ["800px"],
    margin: "auto",
    "div.ff.form-group": {
      maxWidth: "250px",
    },
  }),
};

export const tileStyle = {
  tile: mq({
    //paddingLeft: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    //paddingRight: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    paddingLeft: "7px",
    paddingRight: "7px",
    paddingTop: [
      marginTB[0] / 2,
      marginTB[1] / 2,
      marginTB[2] / 2,
      marginTB[3] / 2,
    ],
    paddingBottom: [
      marginTB[0] / 2,
      marginTB[1] / 2,
      marginTB[2] / 2,
      marginTB[3] / 2,
    ],
    maxWidth: [
      widthPercent(0),
      widthPercent(1),
      widthPercent(2),
      widthPercent(3),
    ],
    flexBasis: [
      widthPercent(0),
      widthPercent(1),
      widthPercent(2),
      widthPercent(3),
    ],
    flexGrow: 0,
    flexShrink: 0,
  }),
  tileLink: {
    background: "#fff",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    //padding: 5,
    //border: '1px solid red',
    "&:hover": {
      textDecoration: "none",
      //background: '#f3f3f3'
    },
  },
  prdImage: {
    //padding: '0 5px',
    //maxWidth: '190px',
    //margin: 'auto',
    //width: '100%',
    // backgroundColor: "grey",
  },
  bg: {
    //height: 0,
    //paddingBottom: '100%',
    paddingTop: "282px",
    backgroundPosition: "center top",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  shortDes: css({
    textAlign: "center",
    marginTop: "0.8rem",
  }),
  name: css(linkText, {
    fontWeight: "bold",
    // fontFamily: variables.familyHeader,
    textAlign: "center",
    marginBottom: "8px",
    // color: "#af5f27",
  }),
  price: css(linkText, {
    marginTop: "auto",
    textAlign: "center",
    marginBottom: "1rem",
  }),
};
