// import React, { useLayoutEffect, useRef } from 'react';
import React from "react";
import { useRouter } from "../../components";
import { SiteLink } from "../../components";
import { ReactComponent as YourSvg } from "./back-base_menu_final-2560px-no-drop-shadow2.svg";
// import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  const links = [
    {
      url: "/accommodation",
      title: "ACCOMMODATION",
      icon: "Accomodation_Icon.png",
      iconHover: "Accomodation_Icon.png",
    },
    {
      url: "/wines",
      title: "WINES",
      icon: "Wines_Icon.png",
      iconHover: "Wines_Icon.png",
    },
    {
      url: "/cellar-door",
      title: "CELLAR",
      icon: "Cellar_Door_Icon.png",
      iconHover: "Cellar_Door_Icon.png",
    },
    {
      url: "/our-story",
      title: "ABOUT",
      icon: "About_Icon.png",
      iconHover: "About_Icon.png",
    },
    {
      url: "#contact",
      title: "CONTACT",
      icon: "Contact_Icon.png",
      iconHover: "Contact_Icon.png",
    },
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  };

  const linkElts = links.map((link, index) => (
    <li
      key={index}
      className={`bottomMenu__li ${isCurrent(link.url) ? "selected" : ""}`}
    >
      <SiteLink
        to={link.url}
        className={`bottomMenu__link bottomMenu__link--${index}`}
      >
        <div className='icon'>
          <img
            className='normal'
            src={`/assets/navicons/${link.icon}`}
            alt={link.title}
          />
          <img
            className='selected'
            src={`/assets/navicons/${link.iconHover}`}
            alt={link.title}
          />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ));

  // const bottomMenu = useRef(null);
  // useLayoutEffect(() => {
  //   //console.log('useLayoutEffect');
  //   const slider = new BottomDrawer(bottomMenu.current);
  //   setTimeout(() => slider.init(), 1000) ref={bottomMenu}
  // }, [])

  return (
    <>
      <nav className='bottomMenu'>
        <ul className='bottomMenu__links'>{linkElts}</ul>
        <YourSvg className='bottom__bg__svg' />
        <div className='dumy__bottom_bg'></div>
      </nav>
    </>
  );
}
