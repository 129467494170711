import { css } from "@emotion/core";
import { mq } from "../../../cssInJs";

export default {
  testimonialItem: css(
    mq({
      maxWidth: "300px",
      backgroundColor: "transparent",
      opacity: ".9",
      textAlign: "center",
      color: "#000",
      padding: "10px",
      //height: '100%',
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      margin: "auto",
      height: ["438px", "490px"],
    })
  ),

  testimonialItem_title: css(
    mq({
      marginBottom: "0px",
      fontSize: ["2rem", "1.72rem"],
    })
  ),

  testimonialItem_desc: css(
    mq({
      flex: "1 1 auto",
      fontSize: ["1rem", "1rem"],
      marginTop: "1rem",
      //flexGrow: '1',
      //flexShrink: '1'
    })
  ),

  testimonialItem_bottom: css(
    mq({
      fontSize: ["1rem", "0.86rem"],
      marginBottom: "0px",
    })
  ),
};
