import { mq } from "../../../cssInJs";
export default {
  summary: mq({
    marginLeft: [0, null, 30],
    border: "solid 1px #af5f27",
    padding: "0.5rem",
    color: "white",
    borderRadius: " 0.5rem",
    background: "#af5f27",
    marginBottom: "0.5rem",
  }),

  totalHdr: {
    fontSize: "1.1rem",
    marginBottom: "0.5rem",
  },

  totalAmount: {
    fontSize: "1.2rem",
    fontWeight: "bold",
  },

  pickupTime: {
    fontSize: "0.7"
  }
};
