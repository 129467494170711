import React from 'react';
//import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { Item } from '../../items/item';

export function VideoHolder(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, subItems);

  const subItemsElts = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );

  const cssClass = utils.classNames('cms_item', 'videoHolder', item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      {subItemsElts}
    </div>
  );
}