import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

export function PageTiles3(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'));
  const pageTileElts = pages.map((page, index) => (
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit}></Tile>
  ));
  const cssClass = utils.classNames(
    'cms_item',
    'pageTiles tiles3',
    item.cssClass || item.anchorName || ''
  );
  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;

  return (
    <>
    
    <div className="pageTile tile tile3">
      <div className="pageTile__content tile__content">
        <div
          className="tile__bg"
          style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl)
          
          }}
        >
          <div className='pageTile__overlay'>
            <div className="pageTile_details">
              <div className="pageTile__title">{page.pageTitle}</div>
              <div className="pageTile_desc">
                {page.tileDesc || page.description}
              </div>
              {/* eslint-disable-next-line */}
              <SiteLink
                className="tile_button"
                to={disableLink ? '' : page.pageUrl}
              >
                READ MORE
              </SiteLink>
            </div>
          </div>
        </div>        
      </div>
    </div>
    </>
  );
}
