function payload(item, key, defaultVal = '') {
  const found = item.payloads.find(x => x.key === key);
  return found ? found.value : defaultVal;
}

function getCodeJson(item) {
  const code = payload(item, 'Code');
  const codeType = payload(item, 'CodeType');
  if (codeType === 'Json') {
    const codeJson = JSON.parse(code);
    return codeJson
  }
  return null
}


function hasItemInPageDataByItemTypeCode(pageData, itemTypeCode) {
  return hasItemInPageData(pageData, item => item.itemTypeCode === itemTypeCode);
}

// hasItemInPageData(cmsOption.pageData, '')
// matchFn = (item) => item.itemTypeCode === itemTypeCode
function hasItemInPageData(pageData, matchFn) {
  if (!pageData) return false;
  for (let i = 0; i < pageData.sections.length; i++) {
    if (hasItemInItems(pageData.sections[i].items, matchFn)) return true;
  }
  return false;
}

function hasItemInItems(items, matchFn) {
  if (!items) return false;
  for (let i = 0; i < items.length; i++) {
    if (matchFn(items[i])) return true;
    if (hasItemInItems(items[i].items, matchFn)) return true;
  }
  return false;
}

export default { payload, getCodeJson, hasItemInPageData, hasItemInItems, hasItemInPageDataByItemTypeCode };